'use client'
import { Metadata } from 'next'
import { metadataFactory } from '#/src/appConfig'
import ServerErrorStdPage from 'storefront-modules/common/components/ServerErrorStdPage'
import { PageProps } from 'ecosystem'
import { CustomLocale } from '#/src/i18n'
import { getTranslations } from 'next-intl/server'
import { useTranslations } from 'next-intl'

export async function generateMetadata({ params }: PageProps<CustomLocale>): Promise<Metadata> {
  const t = await getTranslations({ locale: params.locale, namespace: 'error.Metadata' })

  return {
    ...metadataFactory({
      locale: params.locale,
      title: t('title'),
      description: t('description'),
      pathname: '/'
    })
  }
}

const ErrorPage = () => {
  const t = useTranslations('error.Metadata')
  return <ServerErrorStdPage text={t('description')} />
}

export default ErrorPage
